import { Component } from "@angular/core";
import { Form, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
declare var PaystackPop;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Drsafehands Payment";
  form: FormGroup
  constructor(
    private fb: FormBuilder
  ) {
    this.form =  this.fb.group({
      'firstname': new FormControl('', Validators.required),
      'phone': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.email),
      'amount': new FormControl('', Validators.min(100))
    })
  }

  payWithPaystack(e) {
    let handler = PaystackPop.setup({
      key: "pk_test_xxxxxxxxxx", // Replace with your public key
      email: this.form.value['email'],
      amount: this.form.value['amount'],
      ref: "" + Math.floor(Math.random() * 1000000000 + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      // label: "Optional string that replaces customer email"
      onClose: function () {
        alert("Window closed.");
      },
      callback: function (response) {
        let message = "Payment complete! Reference: " + response.reference;
        alert(message);
      },
    });
    handler.openIframe();
  }
}
